import { SpeedInsights } from '@vercel/speed-insights/react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import WithFavicon from './WithFavicon'
import Prod from './Prod'
import Collection from './collection/Collection'
import '../styles/normalize.css'
import '../styles/skeleton.css'
import '../styles/Home.css'

function App() {
  return (
    <Router>
      <div id="main-div">
        <Routes>
          <Route
            path="/"
            element={
              <WithFavicon favicon="/favicon-default.png">
                <meta name="robots" content="index, follow" />{' '}
                {/* Favicon pour la route principale */}
                <HeaderWithConditionalDisplay />
                <Prod />
              </WithFavicon>
            }
          />
          <Route
            path="/collection"
            element={
              <WithFavicon favicon="/favicon-collection.png">
                <meta name="robots" content="noindex, nofollow" />{' '}
                {/* Favicon pour la page de collection */}
                <HeaderWithConditionalDisplay />
                <Collection />
              </WithFavicon>
            }
          />
          <Route path="*" element={<RedirectToExternal />} />
        </Routes>
        <FooterWithConditionalDisplay />
      </div>
      <SpeedInsights />
    </Router>
  )
}

// Composant pour afficher ou non le Header en fonction du chemin
function HeaderWithConditionalDisplay() {
  const location = useLocation()

  // Affiche le Header sauf si le chemin est "/collection"
  return location.pathname === '/collection' ||
    location.pathname === '/collection/' ? null : (
    <Header />
  )
}

// Composant pour afficher ou non le Footer  en fonction du chemin
function FooterWithConditionalDisplay() {
  const location = useLocation()

  // Affiche le Header sauf si le chemin est "/collection"
  return location.pathname === '/collection' ||
    location.pathname === '/collection/' ? null : (
    <Footer />
  )
}

// Composant pour rediriger vers www.monsite.com en préservant le chemin de l'URL
function RedirectToExternal() {
  const currentPath =
    window.location.pathname + window.location.search + window.location.hash
  window.location.replace(`https://www.ngoberville.com${currentPath}`)
  return null
}

export default App
