import { useEffect, useState, useRef } from 'react'
import default_cover from '../../assets/images/collection/default_cover.png'
import platformImages from '../../datas/collection/platforms' // Chemin vers le fichier index.js contenant les imports
import Slider from 'react-slick' // Importe la bibliothèque du carrousel
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function checkImageExists(url) {
  return new Promise((resolve) => {
    const img = new Image()
    img.src = url

    img.onload = () => resolve(true) // L'image est valide
    img.onerror = () => resolve(false) // L'image est invalide ou n'existe pas
  })
}

function GameInfoModal({
  game,
  onClose,
  platforms,
  gamesPlatforms,
  owners,
  onModifyGame,
}) {
  const [gameData, setGameData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const [coverUrl, setCoverUrl] = useState('')
  const [screenshots, setScreenshots] = useState([])
  const [ownerName, setOwnerName] = useState('')

  const [tooltipStyle, setTooltipStyle] = useState({
    display: 'none',
    left: 0,
    top: 0,
  })
  const [tooltipText, setTooltipText] = useState('')

  const modalRef = useRef(null) // Référence au conteneur du modal

  const carouselSettings = {
    dots: true, // Activer les points de navigation
    infinite: true, // Boucler en fin de slides
    speed: 500, // Vitesse de transition
    slidesToShow: 1, // Nombre d'images affichées simultanément
    slidesToScroll: 1, // Nombre d'images à faire défiler à chaque clic
    arrows: true, // Activer les flèches de navigation
  }

  const handleMouseEnter = (e, text) => {
    if (modalRef.current) {
      const modalRect = modalRef.current.getBoundingClientRect()

      const mouseX = e.clientX

      const targetRect = e.currentTarget.getBoundingClientRect()

      let tooltipLeft = mouseX - modalRect.left

      if (tooltipLeft + targetRect.width > modalRect.width) {
        tooltipLeft = modalRect.width - targetRect.width
      }

      if (tooltipLeft < 0) {
        tooltipLeft = 0
      }

      setTooltipText(text)
      setTooltipStyle({
        display: 'block',
        left: tooltipLeft,
      })
    }
  }

  const handleMouseLeave = () => {
    setTooltipStyle({ display: 'none', left: 0 })
  }

  useEffect(() => {
    let isMounted = true

    const fetchGameData = async () => {
      try {
        if (game.igdb_id) {
          const response = await fetch(
            `/api/fetch-game/${game.igdb_id}?full=true`
          )
          if (!response.ok) {
            throw new Error('Erreur lors de la récupération des données IGDB')
          }
          const data = await response.json()
          if (isMounted) setGameData(data) // Met à jour les données du jeu si le composant est monté
          // Vérifie et récupère la jaquette si disponible
          if (data.cover) {
            const coverResponse = await fetch(
              `/api/fetch-cover/${data.cover}?size=t_cover_big_2x`
            )
            const coverData = await coverResponse.json()
            if (isMounted && coverData.url) {
              setCoverUrl(`https:${coverData.url}`) // Définit l'URL complète de la jaquette
            }
          }
          // Vérifie et récupère les screenshots si disponibles
          if (data.screenshots) {
            const screenshotUrls = await Promise.all(
              data.screenshots.map(async (id) => {
                const response = await fetch(`/api/fetch-screenshot/${id}`)
                const data = await response.json()
                return `${data.url}` // Retourne l'URL du screenshot
              })
            )
            if (isMounted) setScreenshots(screenshotUrls) // Définit les URLs des screenshots
          }
        } else {
          setGameData({
            name: game.name,
            cover: null,
            screenshots: [],
          })

          if (game.cover_url) {
            const exists = await checkImageExists(game.cover_url)
            if (isMounted) {
              setCoverUrl(exists ? game.cover_url : default_cover)
            }
          } else if (isMounted) {
            setCoverUrl(default_cover)
          }
          setIsLoading(false)
        }
      } catch (error) {
        console.error(error)
        if (isMounted) setError('Erreur lors de la récupération des données.')
        // Fermer le modal après 3 secondes si une erreur survient
        setTimeout(() => {
          onClose()
        }, 3000)
      } finally {
        if (isMounted) setIsLoading(false)
      }
    }
    fetchGameData()
    return () => {
      isMounted = false
    }
  }, [game.igdb_id, game.cover_url, checkImageExists])

  useEffect(() => {
    if (game && owners.length > 0 && gamesPlatforms.length > 0) {
      let isMounted = true

      const ownerIds = gamesPlatforms
        .filter((gp) => gp.game_id === game.id)
        .map((gp) => gp.owner_id)

      const ownerNames = owners
        .filter((owner) => ownerIds.includes(owner.id))
        .map((owner) => owner.name)

      if (isMounted) setOwnerName(ownerNames.join(', '))

      return () => {
        isMounted = false
      }
    }
  }, [game, owners, gamesPlatforms])

  const getPlatformStyles = (platformName) => {
    const isOnlyPSPlus = platforms
      .filter((p) => p.name === platformName)
      .every((p) => p.isOnlyPSPlus)
    return isOnlyPSPlus ? 'platform-only-psplus' : ''
  }

  const formatPlatformInfo = (platforms) => {
    const platformMap = platforms.reduce((acc, platform) => {
      if (!acc[platform.name]) {
        acc[platform.name] = {
          subplatforms: [],
          isOnlyPSPlus: true,
          logo: platformImages[platform.name] || '',
        }
      }

      if (platform.subplatforms) {
        acc[platform.name].subplatforms = [
          ...new Set([
            ...acc[platform.name].subplatforms,
            ...platform.subplatforms.map((sp) => sp.name),
          ]),
        ]
      }

      acc[platform.name].isOnlyPSPlus =
        acc[platform.name].isOnlyPSPlus && platform.isOnlyPSPlus

      return acc
    }, {})

    return Object.entries(platformMap).map(
      ([platformName, { subplatforms, logo }]) => {
        const platformText =
          subplatforms.length > 0
            ? `${platformName} (${subplatforms.join(', ')})`
            : platformName

        return (
          <span
            key={platformName}
            onMouseEnter={(e) => handleMouseEnter(e, platformText)}
            onMouseLeave={handleMouseLeave}
            style={{ position: 'relative' }}
          >
            <img
              src={logo}
              alt={platformName}
              className={`${getPlatformStyles(platformName)} platform-logo`}
            />
          </span>
        )
      }
    )
  }

  const timestampToDate = (timestamp) => {
    const date = new Date(timestamp * 1000)
    const day = date.getUTCDate()
    const month = date.getUTCMonth() + 1
    const year = date.getUTCFullYear()
    return `${day}/${month}/${year}`
  }

  const formatReleaseDate = (timestamp, year) => {
    if (timestamp) {
      return timestampToDate(timestamp)
    } else if (year) {
      return `${year}`
    }
  }

  if (isLoading) {
    return <div className="modal-loading">Chargement...</div>
  }

  if (error) {
    return <div className="modal-error">{error}</div>
  }

  return (
    <div
      onClick={(e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
          onClose()
        }
      }}
      className="modal-overlay"
    >
      <div className="modal-content" ref={modalRef}>
        <button onClick={onClose} className="close-button">
          X
        </button>

        <h2>{gameData?.name || game.name}</h2>
        <h3>
          {formatReleaseDate(
            gameData?.first_release_date,
            gameData?.release_year
          )}
        </h3>

        <div className="media-container">
          <img
            className="media-cover"
            src={coverUrl || default_cover} // Utiliser coverUrl ou default_cover si non disponible
            alt={gameData?.name || game.name}
          />
          <div
            className="media-details"
            style={{ display: screenshots.length === 0 ? 'none' : 'block' }}
          >
            <div className="media-carousel">
              {screenshots.length > 0 && (
                <Slider {...carouselSettings} className="carousel">
                  {screenshots.map((url, index) => (
                    <div key={index} className="carousel-slide">
                      <img src={url} alt={`Screenshot ${index + 1}`} />
                    </div>
                  ))}
                </Slider>
              )}
            </div>

            {gameData?.summary && (
              <p className="media-summary">{gameData.summary}</p>
            )}
          </div>
        </div>

        <div className="platform-info">
          <h4>Possédé sur :</h4>
          {formatPlatformInfo(platforms)}
          <div
            className="tooltip"
            style={{
              left: tooltipStyle.left,
              top: tooltipStyle.top,
              display: tooltipStyle.display,
            }}
          >
            {tooltipText}
          </div>
        </div>

        {ownerName && (
          <p className="owners-infos">Propriétaire(s) : {ownerName}</p>
        )}

        {game.is_free && <p className="free-info">Jeu gratuit</p>}

        {game && <p className="comments">{game.comments}</p>}

        <button onClick={() => onModifyGame(game.id)}>Modifier</button>
      </div>
    </div>
  )
}

export default GameInfoModal
